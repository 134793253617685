import { headerHeight } from '../helper/constants';

class AnchorLinks extends HTMLElement {
  constructor() {
    super();
    this.sections = document.querySelectorAll('[id*="shopify-section-template--"]');
    this.sectionArray = Array.from(this.sections).map((section) => section.id);
    console.log(this.sectionArray);
    this.anchors = this.querySelectorAll('a');
  }

  connectedCallback() {
    this.anchors.forEach((link, index) => {
      const selectedIndex = link.getAttribute('section-index');
      if (this.sectionArray[selectedIndex]) {
        link.href = `#${this.sectionArray[selectedIndex]}`;
        link.addEventListener('click', this.onAnchorClick.bind(this));
      }
    });
  }

  onAnchorClick(event) {
    event.preventDefault();
    const selectedtarget = event.target.closest('a');
    const selectedIndex = selectedtarget.getAttribute('section-index');
    const targetSection = document.getElementById(this.sectionArray[selectedIndex]);
    console.log(targetSection);
    if (targetSection) {
      window.scrollTo({
        top: targetSection.offsetTop - (headerHeight() + 16), // Adjust the margin here
        behavior: 'smooth'
      });
    }
  }
}

if (!window.customElements.get('anchor-links')) {
  window.customElements.define('anchor-links', AnchorLinks);
}
